import { graphql } from "gatsby"
import Releases from "@views/Releases"

export const pageQuery: any = graphql`
  query ReleasesByTag(
    $tag: String!
  ) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { tag: { eq: $tag } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        fields {
          slug
        }
        excerpt
        frontmatter {
          title
          date(formatString: "MMMM DD, YYYY")
          description
          tag
        }
      }
    }
  }
`

export default Releases
