import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import colors from "@constants/colors"
import {
  h2Styles,
  h4Styles,
  paragraph18Styles,
  smallcapsBold13Styles,
} from "@constants/typography"
import Layout from "@components/Layout"
import Helmet from "@components/Helmet"

const Container = styled.div`
  padding: calc(0.5 / 12 * 100vw) calc(1 / 12 * 100vw);
`

const Content = styled.ol`
  margin: 0;
  padding: 0;
  list-style: none;
`

const Header = styled.h2`
  ${h2Styles}
  margin-bottom: 48px;
`

const Release = styled.li`
  margin: 36px 0;
  padding: 0;
`

const Title = styled.h4`
  ${h4Styles}
  margin-bottom: 6px;

  &:hover {
    text-decoration: underline;
  }
`

const Date = styled.div`
  ${smallcapsBold13Styles}
  color: ${colors.mediumDarkGray};
`

const Excerpt = styled.div`
  ${paragraph18Styles}
  color: ${colors.mediumDarkGray};
`

const Releases = ({ pageContext, data }) => {
  const title = data.site.siteMetadata?.title || `UAG Releases`
  const posts = React.useMemo(() => {
    return data.allMarkdownRemark.nodes
  }, [data.allMarkdownRemark])

  return (
    <Layout>
      <Helmet title={title} />
      <Container>
        <Content>
          <Header>{`Releases${
            pageContext.tag ? `: ${pageContext.tag}` : ""
          }`}</Header>
          {posts.map((post) => {
            const title = post.frontmatter.title || post.fields.slug
            return (
              <Release key={post.fields.slug}>
                <Title>
                  <Link to={`/releases${post.fields.slug}`} itemProp="url">
                    {title}
                  </Link>
                </Title>
                <Date>{post.frontmatter.date}</Date>
                <Excerpt
                  dangerouslySetInnerHTML={{
                    __html: post.frontmatter.description || post.excerpt,
                  }}
                  itemProp="description"
                />
              </Release>
            )
          })}
        </Content>
      </Container>
    </Layout>
  )
}

export default Releases
